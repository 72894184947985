
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, Search, CommandColumn, Page, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import { L10n, setCulture } from '@syncfusion/ej2-base';

import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import agent from '@/services/agent';
import { resultSheetServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { PropertyAccountGroup, ResultSheetAccountGroup, ResultSheetAccountGroupGetResponse, ResultSheetAccountGroupResponse } from './AccountMapping.model';
import { DialogUtility } from '@syncfusion/ej2-vue-popups';
import { API_KEY } from './Constants';

let _data: PropertyAccountGroup = {} as PropertyAccountGroup;
let _form: ResultSheetAccountGroup = {} as ResultSheetAccountGroup;
let Confirmation: any = undefined;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn
    },
    provide: {
        grid: [Toolbar, Resize, Search, CommandColumn, Page,Sort]
    },
    data() {
        return {
            data: _data,
            form: _form,
            isUpdate: false,
            searchOptions: { fields: ['Name', 'SwedishName', 'Declaration'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            locale: ''
        }
    },
    created() {
        agent.setBaseUrl(resultSheetServiceBaseURL);

        this.gridLocalize();
    },
    mounted() {
        this.dataStateChange(this.initState());
    },
    methods: {
        initState(): any {
            let state = {
                skip: 0,
                take: this.pageSettings.pageSize
            };
            return state;
        },
        dataStateChange(state: any) {
            this.gridLocalize();
            this.loadAccountGroup(state);
        },
        async loadAccountGroup(state: any) {
            if (state.action) {
                delete state.action;
            }

            const gridAccountGroup:any = this.$refs.gridAccountGroup;
            if(gridAccountGroup) gridAccountGroup.hideSpinner();

            const response = await agent.post<ResultSheetAccountGroupGetResponse>(API_KEY.GET_ACCOUNT_GROUPS, { ...state });
            if (response) {
                this.data = response.result;
            }
        },
        onUpsert(event: any) {
            event.preventDefault();

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }

            this.$emit('accountGroupUpdated');
        },
        async onSave() {
            let post = { ...this.form };

            const response = await agent.post<ResultSheetAccountGroupResponse>(API_KEY.POST_ACCOUNT_GROUPS, post);
            if (response && response.result) {
                toasterService.success(this.$t("msgSave"));
                this.loadAccountGroup(this.initState());
                this.reset();
                this.close();
            }
        },
        async onEdit() {
            let put = { ...this.form };

            const response = await agent.put<ResultSheetAccountGroupResponse>(API_KEY.PUT_ACCOUNT_GROUPS, put);
            if (response && response.result) {
                toasterService.success(this.$t("msgUpdate"));
                this.loadAccountGroup(this.initState());
                this.reset();
                this.close();
            }
        },
        async onDelete() {
            let put = { ...this.form };

            const response = await agent.delete<ResultSheetAccountGroupResponse>(API_KEY.DELETE_ACCOUNT_GROUPS(put.id));
            if (response && response.result) {
                toasterService.success(staticMessage.delete);
                this.loadAccountGroup(this.initState());
                this.reset();
                this.$emit('accountGroupUpdated');
            }
        },
        reset() {
            this.form = {} as ResultSheetAccountGroup;
            this.isUpdate = false;
        },
        gridLocalize() {
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: ResultSheetAccountGroup }) {
            this.isUpdate = true;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureWantToDelete"),
                            okButton: {
                            text: this.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDelete();
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    case 'Edit':
                        this.form = args.rowData;
                        modal.Open('accountUnitModal');
                        break;
                    default: break;
                }
            }
        },
        open() {
            this.form = {} as ResultSheetAccountGroup;
            this.isUpdate = false;
            modal.Open('accountUnitModal');
        },
        close() {
            this.form = {} as ResultSheetAccountGroup;
            modal.Close('accountUnitModal');
        },
    }
});
