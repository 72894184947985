
import agent, { masterServiceBaseURL } from '@/services/agent';
import { defineComponent } from 'vue';
import { RecoverPassword, RecoverPasswordResponse } from './RecoverPassword.model';
import authService from '@/services/authService';
import router from '@/router/router';
import preLoader from '@/services/preLoader';
import toasterService from '@/services/toasterService';

let _recoverPasswordForm: RecoverPassword = {} as RecoverPassword;

export default defineComponent({
  created() {
    this.classAddRemove();
    agent.setBaseUrl(masterServiceBaseURL);
  },
  data() {
    return {
      form: _recoverPasswordForm,
      isRecoverPasswordLoader: false,
    }
  },
  mounted() {
    this.form = {} as RecoverPassword;
    preLoader.hide();
    if (location.search) {
      let params = this.split(location.search);
      agent.postAnonymous<RecoverPasswordResponse>(`v1/account/validateRecoveryKey`, params).then((response) => {
        if (!response.result.isValid) {
          toasterService.error(response.result.message, 5000);
          router.push("/login");
        }
        else {
          this.form.recoveryKey = params.recoveryKey;
        }
      });
    } else {
      router.push("/");
    }
  },
  methods: {
    onRecoverPassword(event: any) {
      event.preventDefault();

      let post = { ...this.form };

      this.isRecoverPasswordLoader = true;

      agent.postAnonymous<RecoverPasswordResponse>(`v1/account/recoverPassword`, post).then((response) => {
        if (response.result) {
          this.isRecoverPasswordLoader = false;
          this.form = {} as RecoverPassword;
          this.$router.push("/home");
        }
      }).catch(() => { });
    },
    split(url: string) {
      let regex = /[?&]([^=#]+)=([^&#]*)/g;
      let params = [];
      let match;

      while ((match = regex.exec(url))) {

        const paramName = decodeURIComponent(match[1]);

        const paramValue = decodeURIComponent(match[2]);

        params.push({ paramName: paramName, value: paramValue });

      }
      let payload = {
        recoveryKey: params.find(x => x.paramName.indexOf("recoveryKey") > -1)!.value,
      }
      return payload;
    },
    RedirectTo() {
      const isExpired = authService.isExpired(authService.getToken());
      if (!isExpired) router.push("/");
    },
    classAddRemove() {
      var bodyElement = document.body;
      var sidbarminiClass = bodyElement.classList.contains("sidebar-mini");
      var layoutfixedClass = bodyElement.classList.contains("layout-fixed");
      var registerpageClass = bodyElement.classList.contains("register-page");
      if (sidbarminiClass) bodyElement.classList.remove('sidebar-mini');
      if (layoutfixedClass) bodyElement.classList.remove('layout-fixed');
      if (registerpageClass) bodyElement.classList.remove('register-page');
    }
  }
});
