export const API_KEY = {
    RESULT_SHEET:{
        GET_RESULT_SHEET: `v1/resultsheet/getresultsheet`,
        POST_DUPLICATE:`v1/resultsheet/createduplicateresultsheet`,
        POST: `v1/resultsheet`,
        PUT: `v1/resultsheet`,
        DELETE: (id: number) => `v1/resultsheet/${id}`,
    },
    RESULT_SHEET_DESIGN: {
        GET_BY_ID:(id:number)=>`v1/resultsheetdesign/${id}`,
        HEADER_BY_ID:(id:number)=>`v1/resultsheetdesign/getresultsheetdesignheader/${id}`,
        PUT_ORDINAL:`v1/resultsheetdesign/rsdesignordinal`,
        POST:`v1/resultsheetdesign`,
        PUT:`v1/resultsheetdesign`,
        DELETE:(id:number)=>`v1/resultsheetdesign/${id}`
    }
}

export const TABLE_SETTINGS = {
    RESULT_SHEET:{
        SEARCH_OPTION:{ fields: ['ResultSheetName'], operator: 'contains', ignoreCase: true },
        TOOLBARS:['Search'],
        PAGINATION:{
            pageSizes: [10, 20, 50, 100],
            pageSize: 50,
            pageCount: 5,
            state: { skip: 0, take: 50 } as any,
        },
        COMMANDS: [
            { type: 'Copy', buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' } },
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
        ]
    }
}