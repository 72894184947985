
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn,  Resize, Toolbar,Search, CommandColumn, Page, Sort }
    from '@syncfusion/ej2-vue-grids';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

import agent, { resultSheetServiceBaseURL } from '@/services/agent';
import modal from '@/components/common/modalManage';
import { Property,BalanceSheetDesign, BalanceSheetDesignResponse, BalanceSheetResponse, BalanceSheetRow, BalanceSheetRowResponse } from './BalanceSheet.model';
import { defineComponent } from 'vue';
import toasterService from '@/services/toasterService';
import staticMessage from '@/components/common/staticMessage';
import radio from '@/components/common/radioManage';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import authService from '@/services/authService';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { API_KEY, TABLE_SETTINGS } from './Constants';

let _balanceSheetData: Property = {} as Property
let _balanceSheetListData: BalanceSheetRow[] = [];
let _balanceSheetDesignData: BalanceSheetDesign[] = [];
let _balanceSheetDesignHeaders: BalanceSheetDesign[] = [];
let _balanceSheetform: BalanceSheetRow = {} as BalanceSheetRow;
let _balanceSheetDesignForm: BalanceSheetDesign = {} as BalanceSheetDesign;

let Confirmation:any = null;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        "ejs-dropdownlist": DropDownListComponent
    },
    created(){
        agent.setBaseUrl(resultSheetServiceBaseURL);
        this.gridLocalize();
    },
    data() {
        return {
            balanceSheet: {
                data: _balanceSheetData,
                form: _balanceSheetform,
                selectedId: 0,
                list: _balanceSheetListData,
                isUpdate: false,
                searchOptions: TABLE_SETTINGS.BALANCE_SHEET.SEARCH_OPTION,
                toolbar: TABLE_SETTINGS.BALANCE_SHEET.TOOLBARS,
                pageSettings: TABLE_SETTINGS.BALANCE_SHEET.PAGINATION,
                commands: TABLE_SETTINGS.BALANCE_SHEET.COMMANDS,
                loadingIndicator: { indicatorType: 'Shimmer' }
            },
            balanceSheetDesign: {
                data: _balanceSheetDesignData,
                dataHeaders: _balanceSheetDesignHeaders,
                headerFields: { text: 'header', value: 'id' },
                form: _balanceSheetDesignForm,
                selectedId: 0,
                isUpdate: false,
                isHeader: true,
                oldIndexSubHeader:-1,
                oldIndexHeader:-1,
                onDragHeaderId:0,
                onDropHeaderId:0,
                subRowDragged: {} as BalanceSheetDesign
            },
            locale: '',
            dragItem: {} as BalanceSheetDesign
        }
    },
    provide: {
        grid: [Toolbar,Resize,Search, CommandColumn, Page,Sort]
    },
    mounted() {
        this.dataStateChange(this.initState(this.balanceSheet.pageSettings.state));
    },
    methods: {
        initState(paramState:any): any {
            let state = {
                ...paramState
            };
            return state;
        },
        dataStateChange(state: any) {
            const gridBalanceSheet:any = this.$refs.gridBalanceSheet;
            if (gridBalanceSheet) {
                gridBalanceSheet.hideSpinner();
            }
            this.gridLocalize();
            this.loadBalanceSheet(state);
        },
        loadBalanceSheet(state:any) {
            if (state.action) {
                delete state.action;
            }

            agent.post<BalanceSheetRowResponse>(API_KEY.BALANCE_SHEET.GET_BALANCE_SHEET,{...state}).then((response) => {
                if (response) {
                    this.balanceSheet.data = response.result;
                    this.balanceSheet.list = response.result.result;
                    console.log({balanceSheetList:this.balanceSheet.list});
                }
            }).catch(() => { });
        },
        loadBalanceSheetDesign(id: number) {
            agent.get<BalanceSheetDesignResponse>(API_KEY.BALANCE_SHEET_DESIGN.GET_BY_ID(id)).then((response) => {
                if (response) {
                    this.balanceSheetDesign.data = response.result;
                }
            }).catch(() => { });
        },
        loadBalanceSheetDesignHeader(id: number) {
            agent.get<BalanceSheetDesignResponse>(API_KEY.BALANCE_SHEET_DESIGN.HEADER_BY_ID(id)).then((response) => {
                if (response) {
                    this.balanceSheetDesign.dataHeaders = response.result;
                }
            }).catch(() => { });
        },
        changeBalanceSheet() {
            const id = Number(this.balanceSheet.selectedId);
            console.log({balanceSheetId:this.balanceSheet.selectedId});
            this.loadBalanceSheetDesign(id);
            this.loadBalanceSheetDesignHeader(id);
        },
        onEditBalanceSheetDesign(balanceSheetDesign: BalanceSheetDesign) {
            
            this.resetBalanceSheetDesignForm();
            this.balanceSheetDesign.form = balanceSheetDesign;
            const put = {...balanceSheetDesign};
            radio.checked("headerSubheader", put.isSubHeader?"subHeader":"header");
            this.balanceSheetDesign.isHeader = put.isSubHeader===false;
            this.balanceSheetDesign.isUpdate=true;
            this.open(true);
        },
        onDeleteBalanceSheetDesignConfirm(balanceSheetDesign:BalanceSheetDesign){
            this.balanceSheetDesign.form=balanceSheetDesign;
            let title = this.$t("msgSureDeleteSubHeader");
            if(balanceSheetDesign.isSubHeader){
                title=this.$t("msgSureDeleteSubHeader");
            }else{
                title= this.$t("msgSureDeleteHeaderSubHeader");
            }
            const app = this;
            Confirmation = DialogUtility.confirm({
                title: this.$t("deleteConfiramtion"),
                content: title,
                okButton: {
                text: app.$t("ok"),
                click: async function () {
                    Confirmation.hide();
                    app.onDeleteBalanceSheetDesign();
                },
                },
                cancelButton: { text: this.$t("cancel") },
                showCloseIcon: true,
                closeOnEscape: true,
                zIndex: 10000,
                animationSettings: { effect: "Zoom" },
            });
        },
        onDeleteBalanceSheetDesign() {
            const balanceSheetDesign = {...this.balanceSheetDesign.form};
            if(balanceSheetDesign.id>0){
                agent.delete<BalanceSheetDesignResponse>(API_KEY.BALANCE_SHEET_DESIGN.DELETE(balanceSheetDesign.id)).then((response) => {
                    if (response.result) {
                        toasterService.success(staticMessage.delete);
                        this.balanceSheetDesign.form = {} as BalanceSheetDesign;
                        this.changeBalanceSheet();
                        modal.Close(staticMessage.modalConfirm);
                    }
                }).catch(() => { });
            }
        },
        onUpsertBalanceSheetDesign(event: any) {
            event.preventDefault();

            if(this.balanceSheetDesign.isUpdate){
                this.onUpdateBalanceSheetDesign(this.balanceSheetDesign.form);
            }else{
               this.onSaveBalanceSheetDesign(this.balanceSheetDesign.form);
            }
        },
        onSaveBalanceSheetDesign(balanceSheetDesign:BalanceSheetDesign){

            const balanceSheetId = { balanceSheetId: this.balanceSheet.selectedId, isSubHeader: this.balanceSheetDesign.isHeader == false };
            const post = { ...balanceSheetId, ...balanceSheetDesign,lastModifiedBy:authService.getUserEmail() };

            if (post.balanceSheetId > 0) {
                    agent.post<BalanceSheetDesignResponse>(API_KEY.BALANCE_SHEET_DESIGN.POST, post).then((response) => {
                        if (response.result) {
                            toasterService.success(this.$t("msgSave"));
                            this.balanceSheetDesign.form = {} as BalanceSheetDesign;
                            this.changeBalanceSheet();
                            this.close(true);
                        }
                    }).catch(() => { });
                }
        },
        onUpdateBalanceSheetDesign(balanceSheetDesign:BalanceSheetDesign){

            const balanceSheetId = { balanceSheetId: this.balanceSheet.selectedId, isSubHeader: this.balanceSheetDesign.isHeader == false };
            const post = { ...balanceSheetId, ...balanceSheetDesign,lastModifiedBy:authService.getUserEmail() };

            if (post.id > 0 && post.balanceSheetId>0) {
                    agent.put<BalanceSheetDesignResponse>(API_KEY.BALANCE_SHEET_DESIGN.PUT, post).then((response) => {
                        if (response.result) {
                            toasterService.success(this.$t("msgUpdate"));
                            this.balanceSheetDesign.form = {} as BalanceSheetDesign;
                            this.changeBalanceSheet();
                            this.close(true);
                        }
                    }).catch(() => { });
                }
        },
        onOrdinal(balanceSheetDesigns:BalanceSheetDesign[]){

            const put = {
                balanceSheetDesigns : balanceSheetDesigns,
                lastModifiedBy:authService.getUserEmail()
            };

            if(put.balanceSheetDesigns.length==0) return;

            agent.put<BalanceSheetDesignResponse>(API_KEY.BALANCE_SHEET_DESIGN.PUT_ORDINAL, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.balanceSheetDesign.form = {} as BalanceSheetDesign;
                    this.changeBalanceSheet();
                    this.close(true);
                }
            }).catch(() => { });
        },
        onSaveBalanceSheet(event: any) {
            event.preventDefault();

            if (this.balanceSheet.form.balanceSheetName.length > 0) {
                if (this.balanceSheet.isUpdate) {
                    let put = { ...this.balanceSheet.form,lastModifiedBy:authService.getUserEmail() };
                    agent.put<BalanceSheetResponse>(API_KEY.BALANCE_SHEET.PUT, put).then((response) => {
                        if (response.result) {
                            this.resetBalanceSheetForm();
                            this.loadBalanceSheet(this.initState(this.balanceSheet.pageSettings.state));
                            toasterService.success(this.$t("msgUpdate"));
                        }
                    }).catch(() => { });
                } else {
                    let post = { balanceSheetName: this.balanceSheet.form.balanceSheetName,lastModifiedBy:authService.getUserEmail() };
                    agent.post<BalanceSheetResponse>(API_KEY.BALANCE_SHEET.POST, post).then((response) => {
                        if (response.result) {
                            this.resetBalanceSheetForm();
                            this.loadBalanceSheet(this.initState(this.balanceSheet.pageSettings.state));
                            toasterService.success(this.$t("msgSave"));
                        }
                    }).catch(() => { });
                }
            }
        },
        onDuplicateBalanceSheet() {
            modal.Close(staticMessage.modalConfirm);

            if (this.balanceSheet.form.id > 0) {
                let upsert = { id: this.balanceSheet.form.id, lastModifiedBy: authService.getUserEmail() };
                agent.post<BalanceSheetResponse>(API_KEY.BALANCE_SHEET.POST_DUPLICATE, upsert).then((response) => {
                    if (response.result) {
                        this.resetBalanceSheetForm();
                        this.loadBalanceSheet(this.initState(this.balanceSheet.pageSettings.state));
                        toasterService.success(this.$t("msgSave"));
                    }
                }).catch(() => { });
            }
        },
        onDeleteBalanceSheet() {
            modal.Close(staticMessage.modalConfirm);

            if (this.balanceSheet.form.id > 0) {
                agent.delete<BalanceSheetResponse>(`v1/balancesheet/${this.balanceSheet.form.id}`).then((response) => {
                    if (response.result) {
                        this.resetBalanceSheetForm();
                        this.loadBalanceSheet(this.initState(this.balanceSheet.pageSettings.state));
                        toasterService.success(staticMessage.delete);
                    }
                }).catch(() => { });
            }

        },
        onDragHeader(row:BalanceSheetDesign){
            this.dragItem = row;
            this.balanceSheetDesign.oldIndexHeader = this.balanceSheetDesign.data.findIndex(x=>x.id==row.id);
        },
        onDropHeader(row:BalanceSheetDesign){
            if (this.dragItem.isSubHeader) {
                this.onDropSubHeader(row);
                return;
            }

            let newIndex = this.balanceSheetDesign.data.findIndex(x=>x.id==row.id)
            let oldData = this.balanceSheetDesign.data;
            const movedItem = oldData.splice(this.balanceSheetDesign.oldIndexHeader, 1)[0];
            this.balanceSheetDesign.data.splice(newIndex, 0, movedItem);
            this.onOrdinal(this.balanceSheetDesign.data);
        },
        onDragSubHeader(subRow:BalanceSheetDesign, header: BalanceSheetDesign){
            this.dragItem = subRow;
            this.balanceSheetDesign.onDragHeaderId = header.id;
            this.balanceSheetDesign.subRowDragged = subRow;
        },
        onDropSubHeader(header:BalanceSheetDesign){
            if (header.id == this.balanceSheetDesign.onDragHeaderId) {
                return;
            }

            const subHeaderToUpdate = { ...this.balanceSheetDesign.subRowDragged, parentHeaderId: header.id };
            this.onUpdateBalanceSheetDesign(subHeaderToUpdate);
        },
        commandClick: function (args: { commandColumn: any, rowData: BalanceSheetRow }) {
            this.balanceSheet.isUpdate = false;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.balanceSheet.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureWantToDelete"),
                            okButton: {
                            text: app.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDeleteBalanceSheet();
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    case 'Edit':
                        this.balanceSheet.form = args.rowData;
                        this.balanceSheet.isUpdate = true;
                        break;
                    case 'Copy':
                        this.balanceSheet.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: `${this.$t("msgSureDuplicateBalanceSheet")}:${args.rowData.balanceSheetName}?`,
                            okButton: {
                            text: app.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDuplicateBalanceSheet();
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    default: break;
                }
            }
        },
        radioOnChange(type: string) {
            if (type.toLowerCase() == 'header') this.balanceSheetDesign.isHeader = true;
            if (type.toLowerCase() == 'subheader') this.balanceSheetDesign.isHeader = false;
        },
        resetBalanceSheetDesignForm() {
            this.balanceSheetDesign.form = {} as BalanceSheetDesign;
            this.balanceSheetDesign.isUpdate = false;
            this.balanceSheetDesign.isHeader = true;
        },
        resetBalanceSheetForm() {
            this.balanceSheet.form = {} as BalanceSheetRow;
            this.balanceSheet.isUpdate = false;
        },
        addNewBalanceSheetDesign() {
            this.resetBalanceSheetDesignForm();
            radio.checked("headerSubheader", "header");
            this.open(true);
        },
        gridLocalize(){
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },
        open(isDesign: boolean = false) {
            if (isDesign) {
                modal.Open('balanceSheetDesignModal');
            } else {
                this.balanceSheet.form = {} as BalanceSheetRow;
                modal.Open('balanceSheetModal');
                this.loadBalanceSheet(this.initState(this.balanceSheet.pageSettings.state));
            }
        },
        close(isDesign: boolean = false) {
            if (isDesign) {
                this.balanceSheetDesign.form = {
                    id:0
                } as BalanceSheetDesign;
                this.balanceSheetDesign.isUpdate = false;
                modal.Close('balanceSheetDesignModal');
            } else {
                this.balanceSheet.form = {
                    id:0
                } as BalanceSheetRow;
                this.balanceSheet.isUpdate = false;
                modal.Close('balanceSheetModal');
            }
        },
    }
});

