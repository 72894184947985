export const API_KEY = {
    BALANCE_SHEET:{
        GET_BALANCE_SHEET: `v1/balancesheet/getbalancesheet`,
        POST_DUPLICATE:`v1/balancesheet/createduplicatebalancesheet`,
        POST: `v1/balancesheet`,
        PUT: `v1/balancesheet`,
    },
    BALANCE_SHEET_DESIGN: {
        GET_BY_ID:(id:number)=>`v1/balancesheetdesign/${id}`,
        HEADER_BY_ID:(id:number)=>`v1/balancesheetdesign/getbalancesheetdesignheader/${id}`,
        PUT_ORDINAL:`v1/balancesheetdesign/bsdesignordinal`,
        POST:`v1/balancesheetdesign`,
        PUT:`v1/balancesheetdesign`,
        DELETE:(id:number)=>`v1/balancesheetdesign/${id}`
    }
}

export const TABLE_SETTINGS = {
    BALANCE_SHEET:{
        SEARCH_OPTION:{ fields: ['BalanceSheetName'], operator: 'contains', ignoreCase: true },
        TOOLBARS:['Search'],
        PAGINATION:{
            pageSizes: [10, 20, 50, 100],
            pageSize: 50,
            pageCount: 5,
            state: { skip: 0, take: 50 } as any,
        },
        COMMANDS: [
            { type: 'Copy', buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' } },
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
        ]
    }
}