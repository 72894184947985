
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn, Resize, Toolbar, CommandColumn, Page, Group, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import ConfirmComponent from '@/components/molecules/ConfirmComponent.vue';
import agent, { masterServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { PermissionScope, PermissionScopeGetResponse, PermissionScopeResponse, Property } from './PermissionScope.model';
import authService from '@/services/authService';
let _permissionscopeData: Property = {} as Property;
let _permissionscopeForm: PermissionScope = {} as PermissionScope;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        ConfirmComponent
    },
    provide: {
        grid: [Toolbar, Resize, CommandColumn, Page, Sort, Group]
    },
    created() {
        agent.setBaseUrl(masterServiceBaseURL);

        this.locale = 'en-grid';
        if (this.$i18n.locale == 'se') {
            setTimeout(() => {
                this.locale = 'se-grid';
                setCulture('se-grid');
                L10n.load({
                    ...this.gridLocal
                });
            });
        }
    },
    data() {
        return {
            data: _permissionscopeData,
            form: _permissionscopeForm,
            isUpdate: false,
            isTableLoad: false,
            confirmTitle: "",
            confirmIsDelete: false,
            selectedState: null,
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 10,
                pageCount: 5
            },
            commands: [
                { scope: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { scope: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            locale: '',
            gridLocal: {
                'se-grid': {
                    'grid': {
                        'EmptyRecord': 'Inga poster visas', 'Search': 'Sök',
                        'GroupDropArea': 'Dra en kolumnrubrik hit för att gruppera din kolumn',
                        'UnGroup': 'Klicka här för att avgruppera',
                        'EmptyDataPermissionScopeError': 'DataPermissionScope får inte vara tom vid den första laddningen eftersom kolumner från dataPermissionScope finns i AutoGenerate Column Grid',
                        'Item': 'Artikel',
                        'Items': 'Artikel'
                    },
                    'pager': {
                        'currentPageInfo': '{0} av {1} sidor',
                        'totalItemsInfo': '({0} inlägg)',
                        'firstPageTooltip': 'Till första sidan',
                        'lastPageTooltip': 'Till sista sidan',
                        'nextPageTooltip': 'Till nästa sida',
                        'previousPageTooltip': 'Tillbaka till sista sidan',
                        'nextPagerTooltip': 'Till nästa personsökare',
                        'previousPagerTooltip': 'Gå till föregående personsökare',
                        'pagerDropDown': 'Objekt per sida'
                    }
                }
            }
        }
    },
    mounted() {
        this.dataStateChange(this.initState());
    },
    methods: {
        initState(): any {
            let state = {
                skip: 0,
                take: this.pageSettings.pageSize
            };
            return state;
        },
        dataStateChange(state: any) {
            this.loadPermissionScope(state);
        },
        loadPermissionScope(state: any) {
            if (state.action) {
                delete state.action;
            }

            this.isTableLoad = true;
            agent.get<PermissionScopeGetResponse>(`v1/permissionscope`, state).then((response) => {
                if (response.result) {
                    this.data = response.result;
                    this.isTableLoad = false;
                }
            }).catch(() => { });
        },
        onUpsert(event: any) {
            event.preventDefault();

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }
        },
        onSave() {
            let post = { ...this.form };
            post.createdBy = authService.getUserEmail();

            agent.post<PermissionScopeResponse>(`v1/permissionscope`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.loadPermissionScope(this.initState());
                    this.resetPermissionScope();
                    this.close();
                }
            }).catch(() => { });
        },
        onEdit() {
            let put = { ...this.form };
            put.modifiedBy = authService.getUserEmail();

            agent.put<PermissionScopeResponse>(`v1/permissionscope`, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.loadPermissionScope(this.initState());
                    this.resetPermissionScope();
                    this.close();
                }
            }).catch(() => { });
        },
        onDelete() {
            let put = { ...this.form };

            agent.delete<PermissionScopeResponse>(`v1/permissionscope/${put.id}`).then((response) => {
                modal.Close(staticMessage.modalConfirm);
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    this.loadPermissionScope(this.initState());
                    this.resetPermissionScope();
                }
            }).catch(() => { });
        },
        resetPermissionScope() {
            this.form = {} as PermissionScope;
            this.isUpdate = false;
        },
        commandConfirm() {
            if (this.confirmIsDelete) {
                this.onDelete();
                this.confirmIsDelete = false;
            }
        },
        commandClick: function (args: { commandColumn: any, rowData: PermissionScope }) {
            this.isUpdate = false;
            if (args) {
                switch (args.commandColumn.scope) {
                    case 'Delete':
                        this.confirmTitle = `Are you sure want to delete?`;
                        this.form.id = args.rowData.id;
                        this.confirmIsDelete = true;
                        modal.Open(staticMessage.modalConfirm);
                        break;
                    case 'Edit':
                        this.form = args.rowData;
                        this.isUpdate = true;
                        modal.Open('permissionscopeModal');
                        break;
                    default: break;
                }
            }
        },
        open() {
            this.form = {} as PermissionScope;
            this.isUpdate = false;
            modal.Open('permissionscopeModal');
        },
        close() {
            this.form = {} as PermissionScope;
            modal.Close('permissionscopeModal');
        },
    }
});
