const en = {
  //Common
  update: "Update",
  save: "Save",
  remove: "Remove",
  addNew: "Add New",
  add: "Add",
  close: "Close",
  actions: "Actions",
  all: "All",
  edit: "Edit",
  delete: "Delete",
  selectAll: "Select All",
  swedish: "Swedish",
  english: "English",
  yes: "Yes",
  no: "No",
  clear: "Clear",
  new: "New",
  msgSureWantToDelete: "Are you sure want to delete?",
  msgSureWantToMapAllCompany: "Are you sure want to map all company?",
  msgSureWantToMap: "Are you sure want to map?",
  msgAllCompany: "All Company",
  msgOnlyThisCompany: "Only This Company",
  msgSureWantToDeleteRole: "The role is being used by user(s). Are you sure want to delete?",
  msgSave: "Save information successfully!",
  msgUpdate: "Update information successfully!",
  msgDelete: "Delete information successfully!",
  msgUnauthorized: "You're Unauthorized! Please contract to the administrator.",
  msgForbidden: "Forbidden!!",
  msgNotFound: "Not Found!!",
  msgServerError: "Server Error!!",
  msgRecoveryKeyNotFound: "Invalid Recovery Key!",
  deleteConfiramtion: "Delete Confirmation",
  mapConfirmation: "Map Confirmation",
  host: "Host",
  keepSingle: "Keep Single",

  //Change Password
  changePassword: "Change Password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  change: "Change",
  logOut:"Log Out",
  myProfile:"My Profile",

  //Home
  home: "Home",

  //Module
  module: "Module",
  name: "Name",
  displayName: "Display Name",
  active: "Active",
  displayNameRequired: "Display Name Required!",
  selectModule: "Select a Module",

  //Customer
  customer: "Customer",
  organization: "Organization",
  server: "Server",
  database: "Database",
  subscriber: "Subscriber",
  type: "Type",
  first: "First",
  last: "Last",
  email: "Email",
  password: "Password",
  retype: "Retype",
  use: "Use",
  internal: "Internal",
  external: "External",
  user: "User",
  test: "Test",
  connection: "Connection",
  register: "Register",
  loading: "Loading",
  modules:"Modules",
  subscriptionDeleteConfirm:
    "Delete also database related to this subscription",
  msgUnableHostDelete: "Unable Host Delete!",
  msgPasswordConfirmPassword: "Password and confirm password aren't match!",
  msgSelectModuleAtLeast: "Please select at least one module!",
  msgSelectDatabase: "Please select database!",
  msgGiveServer: "Please give server name!",
  msgGiveDBUserName: "Please give database user name!",
  msgGiveDBUserPassword: "Please give database user password!",
  msgSelectCustomer:"Please select customer",
  msgModuleError: 'Please correct module errors',
  fetchDatabases: "Fetch Databases",
  msgSelectResponsible: "Please select responsible",
  customerResponsible: "Customer Responsible",

  //Page
  page: "Page",
  title: "Title",
  url: "Url",
  icon: "Icon",
  parent: "Parent",
  ordinal: "Ordinal",
  msgSelectModule: "Please select module",
  msgSelectSubscriberType: "Please select subscriber type",
  msgSelectParent: "Please select parent",
  settings: "Settings",

  //Permission
  permission: "Permission",
  role: "Role",
  msgGiveRole: "Please give role",
  msgSelectOnePageAtLeast: "Please select at least one page",

  //User
  subscription: "Subscription",
  msgSelectRole: "Please select role",
  msgSelectSubscription: "Please select subscription",

  //Cost Center
  costCenter: "Cost Center",
  code: "Code",

  //Cost Center Permission
  scope: "Scope",
  fullName: "Full Name",
  msgSelectPermissionScope: "Please select permission scope",
  msgSelectUser: "Please select user",
  msgSelectCostCenterAtLeast: "Please select at least one cost center",

  //Result Sheet or Balance Sheet (Financial Reporting)
  financial: "Financial",
  reporting: "Reporting",
  account: "Account",
  mapping: "Mapping",
  balance: "Balance",
  sheet: "Sheet",
  design: "Design",
  back: "Back",
  range: "Range",
  manage: "Manage",
  select: "Select",
  default: "Default",
  start: "Start",
  end: "End",
  specific: "Specific",
  row: "Row",
  costCenterLevel: "Cost Center Level",
  msgSureDeleteHeaderSubHeader: "Are you sure delete this header with all sub headers?",
  msgSureDeleteSubHeader: "Are you sure delete this sub header?",
  msgSureDeleteResultSheet: "Are you sure want to delete result sheet",
  msgSureDuplicateResultSheet: "Are you sure want to duplicate result sheet",
  msgSureDeleteBalanceSheet: "Are you sure want to delete balance sheet",
  msgSureDuplicateBalanceSheet: "Are you sure want to duplicate balance sheet",

  //Account Group
  declaration: "Declaration",
  group: "Group",
  sign: "Sign",
  msgSelectAccountGroup: "Please select account group",
  msgSelectSource: "Please select source",
  msgSelectCostCenter: "Please select cost center",
  msgSelectCostCenterLevel: "Please select cost center level",
  addSpecificSubHeader: "Add Specific Header",
  accountGroupName: "Account Group Name",
  accountGroups: "Account Groups",
  isInternal: "Is Internal",
  isRevenue: "Is Revenue",

  //Source
  source: "Source",
  msgGiveSource: "Please give source",

  //Result Sheet
  resultSheet: "Result Sheet",
  manageResultSheet: "Manage Result Sheet",
  addNewRow: "Add New Row",
  header: "Header",
  subHeader: "Sub Header",
  bold: "Bold",
  extraBold: "Extra Bold",
  showSubHeader: "Show Sub Header",
  isPercentage: "Is Percentage",
  resultSheetDesignRow: "Result Sheet Design Row",
  rowName: "Row Name",
  msgSelectResultSheet: "Please select result sheet",
  msgResultSheetNotSelected: "No result sheet selected",
  msgSelectHeader: "Please select header",
  msgSelectSubHeader: "Please select sub header",
  msgSelectSpecificSubHeader: "Please select specific sub header",
  msgGiveDefaultSubHeader: "Please give default sub header",
  msgSelectDefaultSubHeader: "Please select default sub header",
  msgGiveSign: "Please give sign",
  isProfitLevel: "Profit Level",
  isProfitLevelPercentage: "Profit Level(%)",

  //Balance Sheet
  msgBalanceSheetNotSelected: "No balance sheet selected",
  balanceSheetDesignRow: "Balance Sheet Design Row",
  manageBalanceSheet: "Manage Balance Sheet",
  msgSelectBalanceSheet: "Please select balance sheet",

  //Balance Sheet Mapping
  msgSureDeleteBalanceSheetMapping:
    "Are you sure want to delete balance sheet mapping?",

  //////Fortnox

  //Company
  msgSureAllDataWithCompany:
    "All data related to this company will be deleted. Are you sure want to delete?",
  creationTime: "Creation Time",
  refreshTokenExpiryDate: "RefreshToken Expire Date",
  lastSyncedDate: "Last Synced Date",
  msgSelectCompany: 'Please select company',

  //Customer
  companyId: "Company Id",
  address1: "Address1",
  address2: "Address2",
  city: "City",
  customerNumber: "Customer Number",
  organisationNumber: "Organisation Number",
  phone: "Phone",
  zipCode: "Zip Code",

  //Account
  companyName: "Company Name",
  description: "Description",
  number: "Number",
  sRU: "SRU",
  year: "Year",
  vATCode: "VAT Code",
  balanceBroughtForward: "Balance Brought Forward",
  balanceCarriedForward: "Balance Carried Forward",
  syncDate: "Sync Date",
  rangeStart: "Range Start",
  rangeEnd: "Range End",
  find: "Find",

  //Account Chart

  //Financial Year
  id: "Id",
  fromDate: "From Date",
  toDate: "To Date",
  accountCharts: "Account Charts",
  accountingMethod: "Accounting Method",

  //Project
  projectNumber: "Project Number",
  startDate: "Start Date",
  endDate: "End Date",
  status: "Status",
  isDeleted:"Is Deleted",

  //SyncLog
  masterLogId: "Master Log Id",
  requestedBy: "Requested By",
  requestedAt: "Requested At",
  startedAt: "Started At",
  runStatus: "Run Status",
  hasError: "Has Error",
  errorMessage: "Error Message",
  isFullSync: "Full Sync",
  jobRunId: "Job Run Id",
  tryCount: "Try Count",
  syncLogDetails: "Sync Log Details",
  logId: "Log Id",
  completedAt: "Completed At",
  entity: "Entity",

  //CompanySettings
  organizationNumber: "Organization Number",
  address: "Address",
  bG: "BG",
  bIC: "BIC",
  branchCode: "Branch Code",
  contactFirstName: "Contact First Name",
  contactLastName: "Contact Last Name",
  country: "Country",
  countryCode: "Country Code",
  databaseNumber: "Database Number",
  domicile: "Domicile",
  fax: "Fax",
  iBAN: "IBAN",
  pG: "PG",
  phone1: "Phone1",
  phone2: "Phone2",
  taxEnabled: "Tax Enabled",
  vATNumber: "VATNumber",
  visitAddress: "Visit Address",
  visitCity: "Visit City",
  visitCountry: "Visit Country",
  visitCountryCode: "Visit Country Code",
  visitName: "Visit Name",
  visitZipCode: "Visit Zip Code",
  website: "Website",

  //Invoice
  booked: "Booked",
  cancelled: "Cancelled",
  currency: "Currency",
  currencyRate: "Currency Rate",
  currencyUnit: "Currency Unit",
  customerName: "Customer Name",
  documentNumber: "Document Number",
  dueDate: "Due Date",
  externalInvoiceReference1: "External Invoice Reference1",
  externalInvoiceReference2: "External Invoice Reference2",
  invoiceDate: "Invoice Date",
  invoiceType: "Invoice Type",
  noxFinans: "Nox Finans",
  oCR: "OCR",
  voucherNumber: "Voucher Number",
  voucherSeries: "Voucher Series",
  voucherYear: "Voucher Year",
  wayOfDelivery: "Way Of Delivery",
  termsOfPayment: "Terms Of Payment",
  sent: "Sent",
  total: "Total",
  finalPayDate: "Final Pay Date",
  language: "Language",
  lastRemindDate: "Last Remind Date",
  net: "Net",
  notCompleted: "Not Completed",
  offerReference: "Offer Reference",
  orderReference: "Order Reference",
  ourReference: "Our Reference",
  paymentWay: "Payment Way",
  priceList: "Price List",
  printTemplate: "Print Template",
  remarks: "Remarks",
  reminders: "Reminders",
  roundOff: "Round Off",
  taxReduction: "Tax Reduction",
  termsOfDelivery: "Terms Of Delivery",
  totalVAT: "Total VAT",
  vATIncluded: "VAT Included",
  yourOrderNumber: "Your Order Number",
  yourReference: "Your Reference",
  taxReductionType: "Tax Reduction Type",
  isSyncRows: "Sync Rows",
  invoiceDetails: "Invoice Details",

  //Invoice Accural
  invoiceNumber: "Invoice Number",
  period: "Period",
  uRL: "URL",

  //Invoice Payment
  amount: "Amount",
  writeOffExist: "Write Off Exist",
  paymentDate: "Payment Date",

  //Supplier Invoice
  administrationFee: "Administration Fee",
  comments: "Comments",
  credit: "Credit",
  creditReference: "Credit Reference",
  disablePaymentFile: "Disable Payment File",
  externalInvoiceNumber: "External Invoice Number",
  externalInvoiceSeries: "External Invoice Series",
  freight: "Freight",
  givenNumber: "Given Number",
  paymentPending: "Payment Pending",
  roundOffValue: "Round Off Value",
  supplierNumber: "Supplier Number",
  supplierName: "Supplier Name",
  vAT: "VAT",
  vATType: "VAT Type",
  salesType: "Sales Type",
  creditCurrency:"Credit Currency",
  vat:"VAT",
  ocr:"OCR",
  isSyncedRow:"Synced Row",
  isPaymentPending:"Payment Pending",
  vatType:"VAT Type",
  supplierInvoiceDetails: "Supplier Invoice Details",
  articleNumber: "Article Number",
  itemDescription: "Item Description",
  accountDescription: "Account Description",
  debitCurrency: "Debit Currency",
  transactionInformation: "Transaction Information",
  debit: "Debit",
  price: "Price",
  quantity: "Quantity",
  unit: "Unit",
  stockPointCode: "Stock Point Code",
  stockLocationCode: "Stock Location Code",
  rowIndex: "Row Index",
  rowNumber: "Row Number",
  removed: "Removed",

  //Supplier Invoice File Connection
  fileId: "File Id",
  supplierInvoiceNumber: "Supplier Invoice Number",

  //Voucher
  transactionDate: "Transaction Date",
  referenceNumber: "Reference Number",
  referenceType: "Reference Type",
  approvalState: "Approval State",

  //Menu
  costCenterPermission: "Cost Center Permission",
  financialReporting: "Financial Reporting",
  "p&lMapping": "P&L Mapping",
  "p&lDesign": "P&L Design",
  balanceSheetDesign: "Balance Sheet Design",
  balanceSheetMapping: "Balance Sheet Mapping",
  fortnox: "Fortnox",
  company: "Company",
  accountChart: "Account Chart",
  financialYear: "Financial Year",
  project: "Project",
  syncLog: "Sync Log",
  companySettings: "Company Settings",
  invoice: "Invoice",
  invoiceAccural: "Invoice Accural",
  invoicePayment: "Invoice Payment",
  supplierInvoice: "Supplier Invoice",
  supplierInvoiceFileConnection: "Supplier Invoice File Connection",
  voucher: "Voucher",

  // VoucherFIleConnection
  voucherFileConnection: "Voucher File Connection",
  voucherDescription: "Voucher Description",
  doSync: "Do Sync",
  enableSyncing: "Enable Syncing",

  

  // "Account Mapping" :"Account Mapping",
  // "Back Account Mapping" :"Back Account Mapping",
  // "Result Sheet":"Result Sheet",
  // "Balance Sheet":"Balance Sheet",
  // "Back Balance Sheet Mapping" :"Back Balance Sheet Mapping",
  // "Result Sheet Design Row":"Result Sheet Design Row",
  // "Balance Sheet Mapping":"Balance Sheet Mapping",
  // "Balance Sheet Design Row":"Balance Sheet Design Row",
  // "Manage Result Sheet":"Manage Result Sheet",
  //
  // "Manage Source":"Manage Source",
  // "Manage Account Groups":"Manage Account Groups",
  // "Manage Account Range":"Manage Account Range",
  // "Add Account Range":"Add Account Range",

  // "Swedish Name":"Swedish Name",
  // "Declaration":"Declaration",
  // "Actions":"Actions",
  // "Account Group":"Account Group",
  // "English Name":"English Name",

  // "Design":"Design",
  // "No result sheet selected":"No result sheet selected",
  // "Select Result Sheet":"Select Result Sheet",
  // "Select Balance Sheet":"Select Balance Sheet",
  // "Select Balance Sheet Mapping":"Select Balance Sheet Mapping",
  // "Header":"Header",
  // "Sub Header":"Sub Header",
  // "Row Name":"Row Name",
  // "Bold":"Bold",
  // "Extra Bold":"Extra Bold",
  // "Add New Row":"Add New Row",
  // "Show Sub Header":"Show Sub Header",
  // "Is Percentage":"Is Percentage",
  // "Select":"Select",
  // "Source":"Source",

  // "Cost Center Level":"Cost Center Level",
  // "Start":"Start",
  // "End":"End",
  // "Sign":"Sign",
  // "Default Header":"Default Header",
  // "Specific Sub Header":"Specific Sub Header",
  // "Range":"Range",
  // "Add Specific Header":"Add Specific Header",
  // "Close":"Close",
  // "Balance Sub Header":"Balance Sub Header",
  // "Save information successfully":"Save information successfully",
  // "Update information successfully":"Update information successfully",
  // "Delete information successfully":"Delete information successfully",
  // "All (*) marks are required":"All (*) marks are required",
  // "DatabaseNameErrorMessage": "Database Name must be between 1 and 128 characters.",
  // "Customer":"Customer",
  // "User":"User",
  // "Cost Center Permission":"Cost Center Permission",
  // "Fortnox":"Fortnox",
  // "Company":"Company",
  // "Financial Reporting":"Financial Reporting",
  // "P&L Account Mapping":"P&L Account Mapping",
  // "P&L Design":"P&L Design",
  // "Balance Sheet Design":"Balance Sheet Design"


  //Page Build
  pageBuilder: "Page Builder",
  pageBuild: "Page Build",
  pageManage: "Page Manage",
  databaseTableName: "Database Table Name",
  buildForm: "Build Form",
  definition: "Definition",
  input: "Input",
  selectFieldType: "Select Field Type",
  fieldType: "Field Type",
  dropdownRef: "Dropdown Ref",
  selectSchema: "Select Schema",
  selectTable: "Select Table",
  selectColumnId: "Select Column (Id)",
  selectColumnName: "Select Column (Name)",
  hasDatabaseSource: "Has Database Source",
  dropdownData:"Dropdown data",
  selectColumn:"Select Column",
  radioData:"Radio Data",
  checkboxRef:"Checkbox Ref",
  checkboxData:"Checkbox Data",
  defaultDate:"Default Date",
  databaseType:"Database Type",
  decimalPlace:"Decimal Place",
  selectDatabaseType:"Select Database Type",
  size:"Size",
  placeHolder:"Place Holder",
  isMax:"Is Max",
  databaseName:"Database Name",
  isRequired:"Is Required",

  //Page Manage
  importExcel: "Import(.xls/.csv)",
  selectPage: "Select Page",
  ok: "Ok",
  root:"Root",

  pleaseFillAllRequiredFields: "Please fill all required fields",
  dataImportedSuccessfully: "Data imported successfully",

  //Suppliers
  suppliers: 'Suppliers',
  companyname: 'Company Name',
  bankAccountNumber: 'Bank Account Number',
  costcenter: 'Cost Center',
  preDefinedAccount: 'Pre Defined Account',
  costCenterNumber: 'Cost Center Number',
  costCenterName: 'Cost Center Name',

  //Data Sync Configuration
  dataSyncConfiguration: 'Data Sync Configuration',
  featureName: 'Feature Name',
  isSyncFromFortnox: 'Is Sync From Fortnox',
  log: 'Log',
  organizationName: 'Organization Name',

  //Property
  property:"Property",
  selectProperty:"Select Property",
  values:"Values",
  msgGivePropertyName:"Please give property name",
  msgGivePropertyValue:"Please give property value",

  // Power Office
  powerOffice : "Power Office",
  applicationKey: "Application Key",
  clientKey: "Client Key",
  subscriptionKey: " Subscription Key",
  shouldSync: "Should Sync",

  //Auth Setting
  authSetting:"Auth Setting",
  clientId:"Client Id",
  clientSecret:"Client Secret",

  //CostCenterGroup
  costCenterGroup:"Cost Center Group",
  manageCostCenterGroup:"Manage Cost Center Group",
  backCostCenter:"Back Cost Center",
  msgSelectCostCenterGroup:"Please select cost center group",

  isActive:"Is Active",

  //Unit
  units:"Units",
  date:"Date",
  employeeId:"Employee Id",
  expense:"Expense",
  salaryCode:"Salary Code",
  salaryRow:"Salary Row",
  textRow:"Text row",
  deliveryDate:"Delivery date",
  orderDate:"Order date",
  orderType:"Order type",
  personelType:"Personal type",
  personalIdentityNumber:"Personal Identity number",
  nonRecurringTax:"Non recurring tax",
  monthlySalary:"Monthly salary",
  jobTitle:"Job title",
  inactive:"Inactive",
  foraType:"Fora type",
  fromQuantity:"From quantity",
  hours:"Hours",
  scheduleId:"Schedule Id",
  externalUrlConnection:"External Url Connection",
  employmentForm:"Employment Form",
  employmentDate:"Employment date",
  employedTo:"Employed to",
  clearingNo:"Clearing No",
  bankAccountNo:"Bank account no",
  averageWeeklyHours:"Average weekly hours",
  averageHourlyWage:"Average hourly wage",
  autoNonRecurringTax:"Auto Non Recurring Tax",
};

export default en;
