
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn,  Resize, Toolbar,Search, CommandColumn, Page, Sort }
    from '@syncfusion/ej2-vue-grids';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

import { defineComponent } from 'vue';
import SourceComponent from '../Source/SourceComponent.vue';
import agent, { } from '@/services/agent';
import { resultSheetServiceBaseURL } from "@/services/agent";
import modal from '@/components/common/modalManage';
import { ResultSheetAccountGroup, ResultSheetAccountGroupGetResponse, ResultSheetAccountGroupResponse, ResultSheetMapping, ResultSheetMappingGetResponse, ResultSheetMappingRangeResponse, ResultSheetMappingResponse, SpecificSubHeader, ViewCostCenter } from './AccountMapping.model';
import staticMessage from '@/components/common/staticMessage';
import toasterService from '@/services/toasterService';
import { Source, SourceGetResponse } from '../Source/Source.model';
import _ from 'lodash';
import { ResultSheetDesign, ResultSheetDesignResponse, ResultSheetRow, ResultSheetRowResponse } from '../ResultSheetDesign/ResultSheet.model';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import VueSelect from '@/components/common/VueSelect.vue';
import { CostCenterGroup, CostCenterGroupGetResponse } from '../CostCenter/CostCenter.model';
import { DialogUtility } from '@syncfusion/ej2-vue-popups';
import { API_KEY } from './Constants';

let _data: ResultSheetMapping[]=[];
let _form: ResultSheetMapping = {} as ResultSheetMapping;
let _formResultSheetAccountGroup: ResultSheetAccountGroup = {} as ResultSheetAccountGroup;
let _resultSheetData: ResultSheetRow[] = [];
let _resultSheetDesignData: ResultSheetDesign[] = [];
let _accountUnitData: ResultSheetAccountGroup[] = [];
let _sourceData: Source[] = [];
let _selectedResultSheetId: string = "";
let _signData: string[] = ["+", "-"];
let _costCenterLevelData: string[] = [];
let _specificSubHeaderData: SpecificSubHeader[] = []
let Confirmation: any = undefined;

export default defineComponent({
    components: {
    SourceComponent,
    "ejs-grid": EjsGrid,
    "e-columns": EColumns,
    "e-column": EColumn,
    "ejs-dropdownlist": DropDownListComponent,
    VueSelect
},
    data() {
        return {
            manageSource: false,
            manageMapping: true,
            accountUnit: {
                form: _formResultSheetAccountGroup,
            },
            find:{
                resultSheetId:0,
                sourceId:0,
                subHeaderId:0
            },
            subHeaderForm:{
                sourceId:0,
                subHeaderId:0,
                specificSubHeaderId:0,
                costCenterGroupId:0
            },
            mapping: {
                data: _data,
                form: _form,
                selectedId: _selectedResultSheetId,
                listResultSheet: _resultSheetData,
                listResultSheetDesign: _resultSheetDesignData,
                listSpecificResultSheetDesign: _resultSheetDesignData,
                listResultSheetAccountGroup: _accountUnitData,
                listSource: _sourceData,
                listCostCenterLevel: _costCenterLevelData,
                listCostCenterGroup:[] as CostCenterGroup[],
                listSign: _signData,
                listSpecificSubHeaders: _specificSubHeaderData,
                isUpdate: false,
                fieldsResultSheetDesign: { text: 'header', value: 'id' },
                fieldsResultSheetAccountGroup: { text: 'name', value: 'id' },
                fieldsSource: { text: 'name', value: 'id' },
                fieldsCostCenterFind: { text: 'name', value: 'id' },
                fieldsCostCenterLevel: { text: 'costCenterLevel', value: 'costCenterLevel' },
                fieldsCostCenterGroup: { text: 'name', value: 'id' },
                toolbar: ['Search'],
                pageSettings: {
                    pageSizes: [10, 20, 50, 100],
                    pageSize: 50,
                    pageCount: 5,
                    skip:0,
                    take:50
                },
                commands: [
                    { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                    { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
                ],
            },
            locale: ''
        }
    },
    provide: {
        grid: [Toolbar,Resize,Search, CommandColumn, Page,Sort]
    },
    created() {
        agent.setBaseUrl(resultSheetServiceBaseURL);
        
        this.gridLocalize();
    },
    mounted() {
        this.loadResultSheet();
        this.loadResultSheetAccountGroup();
        this.loadSource();
        this.loadCostCenterGroup();
        this.dataStateChange();
    },
    methods: {
        initState(skip:number=0,take:number): any {
            let state = {
                skip: skip,
                take: take
            };
            return state;
        },
        dataStateChange() {
            this.gridLocalize();
            if(this.find.resultSheetId && this.find.resultSheetId > 0) this.loadMapping();
        },
        saveAccountRange() {
            let subHeaders: { subHeaderId: number; costCenterGroupId: number; isNewlyAdded: boolean; }[] = [];
            _.map(this.mapping.listSpecificSubHeaders, item => {
                subHeaders.push({
                    subHeaderId: item.subHeaderId,
                    costCenterGroupId: item.costCenterGroupId,
                    isNewlyAdded: item.isNewlyAdded
                });
            });

            //Make unused when removed cost center and keep cost center group only.
            // const selectedSource = this.mapping.listSource.find(s => s.id === form.sourceId);
            // //Make sure [master] default costcenter and account mapping source what's relation about them. in post request.
            // let costCenter = this.mapping.listCostCenter.find(i=>i.costCenterName == selectedSource?.name);
            // if (costCenter) {
            //     form.costCenterCode = costCenter.costCenterNumber;
            // }

            let post = { ...this.mapping.form, ...{ resultSheetId: this.find.resultSheetId }, ...{ mappingHeaderVMs: subHeaders } };

            agent.post<ResultSheetMappingResponse>(`v1/accountmapping`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.mapping.isUpdate ? this.$t("msgUpdate") : this.$t("msgSave"));
                    this.reset();
                    this.close();
                    this.loadMapping();
                }
            }).catch((err) => { 
                console.log('err: ', err);
            });
        },
        onDelete() {
            let del = { ...this.mapping.form };

            agent.delete<ResultSheetMappingResponse>(`v1/accountmapping/removecompleteaccountrange/${del.accountRangeMasterId}`).then((response) => {
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    modal.Close(staticMessage.modalConfirm);
                    this.loadMapping();
                }
            }).catch(() => { });
        },
        changeResultSheet() {
            this.loadResultSheetDesignPrimaryHeader();
            this.loadMapping();
        },
        addSpecificSubHeader() {
            const isInvalid = this.subHeaderForm.specificSubHeaderId==0
            || this.subHeaderForm.costCenterGroupId==0;

            if(isInvalid) return;

            let isExist = this.mapping.listSpecificSubHeaders.find(x => x.costCenterGroupId === this.subHeaderForm.costCenterGroupId);
            if (isExist) return;

            if(this.subHeaderForm.specificSubHeaderId && this.subHeaderForm.costCenterGroupId ){
                let subHeader = this.mapping.listSpecificResultSheetDesign.find(x => x.id === this.subHeaderForm.specificSubHeaderId);

                this.mapping.listSpecificSubHeaders.push({
                    costCenterGroupId: this.subHeaderForm.costCenterGroupId,
                    costCenterGroupName: this.mapping.listCostCenterGroup.find(x => x.id === this.subHeaderForm.costCenterGroupId)!.name,
                    isNewlyAdded: true,
                    subHeaderId: this.subHeaderForm.specificSubHeaderId,
                    subHeaderName: subHeader!.header
                });
            }
        },
        removeSpecificSubHead(item: SpecificSubHeader) {
            this.mapping.listSpecificSubHeaders =
                this.mapping.listSpecificSubHeaders.filter(
                    x => x.costCenterGroupId !== item.costCenterGroupId);
        },
        changeSubHeader() {
            if (this.mapping.form.subHeaderId) {
                let filter = this.mapping.listResultSheetDesign.filter(x => x.id != this.mapping.form.subHeaderId);
                this.mapping.listSpecificResultSheetDesign = [...filter];
            }
        },
        onSaveResultSheetAccountGroup(event: any) {
            event.preventDefault();

            let post = { ...this.accountUnit.form };

            agent.post<ResultSheetAccountGroupResponse>(`v1/accountgroups`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.loadResultSheetAccountGroup();
                    this.close(true);
                }
            }).catch(() => { });
        },
        loadMapping() {
                let query = `v1/accountmapping/getrangedata`;

                if (this.find.resultSheetId && this.find.resultSheetId > 0) 
                {
                    query = query + `?resultSheetId=${this.find.resultSheetId}`;
                }

                if (this.find.subHeaderId && this.find.subHeaderId > 0) 
                {
                    query = query + `&subHeaderId=${this.find.subHeaderId}`;
                }

                if (this.find.sourceId && this.find.sourceId > 0) 
                {
                    query = query + `&sourceId=${this.find.sourceId}`;
                }

                agent.get<ResultSheetMappingRangeResponse>(query).then((response) => {
                    if (response.result) {
                        this.mapping.data = response.result;
                    }
                }).catch(() => { });
        },
        getCostCenterGroupName(id?:number){
            if(!id) return '';
            const costCenterGroup = this.mapping.listCostCenterGroup.find(x => x.id === id);
            return costCenterGroup ? costCenterGroup.name : '';
        },
        loadMappingEdit(accountGroupId:number,sign:string,accountRangeMasterId:number) {
            const params = {
                groupId: accountGroupId,
                sign: sign,
                accountRangeMasterId: accountRangeMasterId
            };

            agent.get<ResultSheetMappingGetResponse>(`v1/accountmapping/getaccountrangelist`, params).then((response) => {
                if (response.result) {
                    let data = response.result.result.filter(x => x.isSpecificSubHeader === true);
                    const specificSubHeaders = _.map(data,item=>({
                        costCenterGroupId: item.costCenterGroupId!,
                        isNewlyAdded: false,
                        subHeaderId: item.subHeaderId,
                        subHeaderName: item.subHeaderName,
                        costCenterGroupName: this.getCostCenterGroupName(item.costCenterGroupId)
                    }));
                    this.mapping.listSpecificSubHeaders = [...specificSubHeaders];
                    modal.Open('accountMappingModal');
                }
            }).catch(() => { });
        },
        async loadSource() {
            const response = await agent.post<SourceGetResponse>(`v1/source/getsource`,{});
            if (response) {
                const initData = [
                    {
                        id: 0,
                        name: this.$t('msgSelectSource')
                    } as Source
                ]
                const data = response.result.result.sort((a, b) => a.name.localeCompare(b.name));

                this.mapping.listSource = [...initData,...data];

                if(this.mapping.listSource.length==2){
                    this.mapping.form.sourceId = this.mapping.listSource[this.mapping.listSource.length-1].id;
                }
            }
        },
        loadResultSheetAccountGroup() {
            agent.post<ResultSheetAccountGroupGetResponse>(`v1/accountgroups/getaccountgroups`,{}).then((response) => {
                if (response) {
                    const initData = [
                        {
                            id: 0,
                            name: this.$t('msgSelectAccountGroup')
                        } as ResultSheetAccountGroup
                    ]

                    const data = response.result.result.sort((a, b) => a.name.localeCompare(b.name));

                    this.mapping.listResultSheetAccountGroup = [...initData,...data];
                }
            }).catch(() => { });
        },
        loadResultSheetDesignPrimaryHeader() {
            agent.get<ResultSheetDesignResponse>(`v1/resultsheetdesign/getresultsheetdesignprimaryheader/${this.find.resultSheetId}`).then((response) => {
                if (response) {
                    const initData = [
                        {
                            id: 0,
                            header: this.$t('msgSelectSubHeader')
                        } as ResultSheetDesign
                    ]
                    const data = response.result.sort((a, b) => a.swedishName.localeCompare(b.swedishName));

                    this.mapping.listResultSheetDesign = [...initData,...data];
                }
            }).catch(() => { });
        },
        loadResultSheet() {
            agent.post<ResultSheetRowResponse>(`v1/resultsheet/getresultsheet`,{}).then((response) => {
                if (response) {
                    this.mapping.listResultSheet = response.result.result;
                }
            }).catch(() => { });
        },
        loadCostCenterGroup(){
            agent.post<CostCenterGroupGetResponse>(API_KEY.GET_COST_CENTER_GROUP,{}).then((response) => {
                if (response) {
                    const initData = [
                        {
                            id: 0,
                            name: this.$t('msgSelectCostCenterGroup')
                        } as CostCenterGroup
                    ]
                    this.mapping.listCostCenterGroup = [...initData,...response.result.result];
                }
            }).catch(() => { });
        },
        reset() {
            this.mapping.form = {} as ResultSheetMapping;
            this.mapping.isUpdate = false;
            this.resetHeader();
            this.resetSpecificSubHeader();
        },
        resetHeader(){
            this.mapping.form.sign = "+";
            this.mapping.listSpecificSubHeaders = [];

            // const resultSheetAccountGroupTree:any = this.$refs.resultSheetAccountGroupTree;
            // if(resultSheetAccountGroupTree){
            //     resultSheetAccountGroupTree.clear();
            // }
            // const defaultSubHeaderTree:any = this.$refs.defaultSubHeaderTree;
            // if(defaultSubHeaderTree){
            //     defaultSubHeaderTree.clear();
            // }
            
            // const sourceTree:any = this.$refs.sourceTree;
            // if(sourceTree){
            //     sourceTree.clear();
            // }

            this.mapping.form.accountGroupId = 0;
            this.mapping.form.subHeaderId = 0;
            this.mapping.form.sourceId = 0;

            if(this.mapping.listSource.length==2){
                this.mapping.form.sourceId = this.mapping.listSource[this.mapping.listSource.length-1].id;
            }
        },
        resetSpecificSubHeader(){
            const specificSubHeaders:any = this.$refs.specificSubHeaders;
            if(specificSubHeaders){
                specificSubHeaders.clear();
            }

            const resultSheetCostCenterTree:any = this.$refs.resultSheetCostCenterTree;
            if(resultSheetCostCenterTree){
                resultSheetCostCenterTree.clear();
            }

            const costCenterGroup:any = this.$refs.costCenterGroup;
            if(costCenterGroup){
                costCenterGroup.clear();
            }

            this.subHeaderForm = {
                sourceId:0,
                subHeaderId:0,
                costCenterGroupId:0,
                specificSubHeaderId:0
            };
        },
        manage(type: string) {
            switch (type.toLowerCase()) {
                case 'source':
                    this.manageMapping = false;
                    this.manageSource = true;
                    break;
                case 'mapping':
                    this.manageSource = false;
                    this.manageMapping = true;
                    break;
                default: break;
            }
        },
        gridLocalize(){
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },

        commandClick: function (args: { commandColumn: any, rowData: ResultSheetMapping }) {
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.mapping.form.accountRangeMasterId = args.rowData.accountRangeMasterId;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureWantToDelete"),
                            okButton: {
                            text: this.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDelete();
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        modal.Open(staticMessage.modalConfirm);
                        break;
                    case 'Edit':
                        this.mapping.form = args.rowData;
                        this.mapping.isUpdate = args.rowData.accountRangeMasterId>0;
                        if(!this.mapping.isUpdate){
                            if(this.mapping.listSource.length==2){
                                this.mapping.form.sourceId = this.mapping.listSource[this.mapping.listSource.length-1].id;
                            }
                            this.mapping.form.sign = "+";
                        }
                        this.subHeaderForm = {
                            sourceId:0,
                            subHeaderId:0,
                            specificSubHeaderId:0,
                            costCenterGroupId:0
                        };
                        this.loadMappingEdit(this.mapping.form.accountGroupId,this.mapping.form.sign,this.mapping.form.accountRangeMasterId);
                        break;
                    default: break;
                }
            }
        },
        open(isResultSheetAccountGroup: boolean = false) {
            if (isResultSheetAccountGroup) {
                modal.Open('accountUnit');
            } else {
                if (!this.find.resultSheetId || this.find.resultSheetId == 0) {
                    toasterService.warn(this.$t("msgSelectResultSheet"));
                    return;
                }
                this.reset();
                modal.Open('accountMappingModal');
            }
        },
        close(isResultSheetAccountGroup: boolean = false) {
            if (isResultSheetAccountGroup) {
                modal.Close('accountUnit');
            } else {
                modal.Close('accountMappingModal');
            }
        },
    }

});
