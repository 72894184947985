
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn,  Resize, Toolbar,Search, CommandColumn, Page, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import modal from '@/components/common/modalManage';
import staticMessage from '@/components/common/staticMessage';
import ConfirmComponent from '@/components/molecules/ConfirmComponent.vue';
import agent, { resultSheetServiceBaseURL } from '@/services/agent';
import toasterService from '@/services/toasterService';
import { Source, SourceGetResponse, SourceResponse,Property } from './Source.model';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import authService from '@/services/authService';

let _sourceData: Property = {} as Property;
let _sourceForm: Source = {} as Source;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        ConfirmComponent
    },
    provide: {
        grid: [Toolbar,Resize,Search, CommandColumn, Page,Sort]
    },
    created() {
        agent.setBaseUrl(resultSheetServiceBaseURL);

        this.gridLocalize();
    },
    data() {
        return {
            data: _sourceData,
            form: _sourceForm,
            isUpdate: false,
            confirmTitle: "",
            confirmIsDelete: false,
            searchOptions: { fields: ['Name'], operator: 'contains', ignoreCase: true },
            toolbar: ['Search'],
            pageSettings: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 50,
                pageCount: 5,
                state:{}
            },
            commands: [
                { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
            ],
            locale: '',
            gridLocal:{
                'se-grid': {
                        'grid': {
                            'EmptyRecord': 'Inga poster visas', 'Search':'Sök',
                            'GroupDropArea': 'Dra en kolumnrubrik hit för att gruppera din kolumn',
                            'UnGroup': 'Klicka här för att avgruppera',
                            'EmptyDataSourceError': 'DataSource får inte vara tom vid den första laddningen eftersom kolumner från dataSource finns i AutoGenerate Column Grid',
                            'Item': 'Artikel',
                            'Items': 'Artikel'
                        },
                        'pager': {
                            'currentPageInfo': '{0} av {1} sidor',
                            'totalItemsInfo': '({0} inlägg)',
                            'firstPageTooltip': 'Till första sidan',
                            'lastPageTooltip': 'Till sista sidan',
                            'nextPageTooltip': 'Till nästa sida',
                            'previousPageTooltip': 'Tillbaka till sista sidan',
                            'nextPagerTooltip': 'Till nästa personsökare',
                            'previousPagerTooltip': 'Gå till föregående personsökare',
                            'pagerDropDown': 'Objekt per sida'
                        }
                    }
            }
        }
    },
    mounted() {
        this.dataStateChange(this.initState());
    },
    methods: {
        initState(): any {
            let state = {
                skip: 0,
                take: this.pageSettings.pageSize
            };
            return state;
        },
        dataStateChange(state: any) {
            const gridSource:any = this.$refs.gridSource;
            if(gridSource) gridSource.hideSpinner();
            this.gridLocalize();
            this.loadSource(state);
        },
        loadSource(state:any) {
            if (state.action) {
                delete state.action;
            }

            agent.post<SourceGetResponse>(`v1/source/getsource`,{...state}).then((response) => {
                if (response) {
                    this.data = response.result;
                    console.log("result:",this.data);
                }
            }).catch(() => { });
        },
        onUpsert(event: any) {
            event.preventDefault();

            if (this.isUpdate) {
                this.onEdit();
            } else {
                this.onSave();
            }

            this.$emit('sourceUpdated');
        },
        onSave() {
            let post = { ...this.form,lastModifiedBy:authService.getUserEmail() };

            agent.post<SourceResponse>(`v1/source`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.loadSource(this.initState());
                    this.resetSource();
                    this.close();
                }
            }).catch(() => { });
        },
        onEdit() {
            let put = { ...this.form,lastModifiedBy:authService.getUserEmail() };

            agent.put<SourceResponse>(`v1/source`, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.loadSource(this.initState());
                    this.resetSource();
                    this.close();
                }
            }).catch(() => { });
        },
        onDelete() {
            let put = { ...this.form };

            agent.delete<SourceResponse>(`v1/source/${put.id}`).then((response) => {
                modal.Close(staticMessage.modalConfirm);
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    this.loadSource(this.initState());
                    this.resetSource();

                    this.$emit('sourceUpdated');
                }
            }).catch(() => { });
        },
        resetSource() {
            this.form = {} as Source;
            this.isUpdate = false;
        },
        commandConfirm() {
            if (this.confirmIsDelete) {
                this.onDelete();
                this.confirmIsDelete = false;
            }
        },
        gridLocalize(){
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    this.locale = 'se-grid';
                    setCulture('se-grid');
                    L10n.load({
                        ...this.gridLocal
                    });
                });
            }
        },

        commandClick: function (args: { commandColumn: any, rowData: Source }) {
            this.isUpdate = false;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.confirmTitle = this.$t('msgSureWantToDelete');
                        this.form.id = args.rowData.id;
                        this.confirmIsDelete = true;
                        modal.Open(staticMessage.modalConfirm);
                        break;
                    case 'Edit':
                        this.form = args.rowData;
                        this.isUpdate = true;
                        modal.Open('sourceModal');
                        break;
                    default: break;
                }
            }
        },
        open() {
            this.form = {} as Source;
            this.isUpdate = false;
            modal.Open('sourceModal');
        },
        close() {
            this.form = {} as Source;
            modal.Close('sourceModal');
        },
    }
});
