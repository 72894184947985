
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn,  Resize, Toolbar,Search, CommandColumn, Page, Sort }
    from '@syncfusion/ej2-vue-grids';
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";

import agent, { resultSheetServiceBaseURL } from '@/services/agent';
import modal from '@/components/common/modalManage';
import { Property, ResultSheetDesign, ResultSheetDesignResponse, ResultSheetResponse, ResultSheetRow, ResultSheetRowResponse } from './ResultSheet.model';
import { defineComponent } from 'vue';
import toasterService from '@/services/toasterService';
import staticMessage from '@/components/common/staticMessage';
import radio from '@/components/common/radioManage';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import authService from '@/services/authService';
import { API_KEY, TABLE_SETTINGS } from './Constants';
import { DialogUtility } from '@syncfusion/ej2-popups';

let _resultSheetData: Property = {} as Property;
let _resultSheetDesignData: ResultSheetDesign[] = [];
let _resultSheetDesignHeaders: ResultSheetDesign[] = [];
let _resultSheetform: ResultSheetRow = {} as ResultSheetRow;
let _resultSheetDesignForm: ResultSheetDesign = {} as ResultSheetDesign;
let _resultSheetList:ResultSheetRow[]=[];

let Confirmation:any = null;

export default defineComponent({
    components: {
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        "ejs-dropdownlist": DropDownListComponent
    },
    created(){
        agent.setBaseUrl(resultSheetServiceBaseURL);
        
        this.gridLocalize();
    },
    data() {
        return {
            resultSheet: {
                data: _resultSheetData,
                form: _resultSheetform,
                selectedId: 0,
                list: _resultSheetList,
                isUpdate: false,
                searchOptions: TABLE_SETTINGS.RESULT_SHEET.SEARCH_OPTION,
                toolbar: TABLE_SETTINGS.RESULT_SHEET.TOOLBARS,
                pageSettings: TABLE_SETTINGS.RESULT_SHEET.PAGINATION,
                commands: TABLE_SETTINGS.RESULT_SHEET.COMMANDS,
                loadingIndicator: { indicatorType: 'Shimmer' }
            },
            resultSheetDesign: {
                data: _resultSheetDesignData,
                dataHeaders: _resultSheetDesignHeaders,
                headerFields: { text: 'header', value: 'id' },
                form: _resultSheetDesignForm,
                selectedId: 0,
                isUpdate: false,
                isHeader: true,
                rowDragId:0,
                subRowDrag:_resultSheetDesignForm,
                oldIndexHeader: 0
            },
            locale: '',
            dragItem: _resultSheetDesignForm
        }
    },
    provide: {
        grid: [Toolbar,Resize,Search, CommandColumn, Page,Sort]
    },
    mounted() {
        this.dataStateChange(this.initState(this.resultSheet.pageSettings.state));
    },
    methods: {
        initState(paramState:any): any {
            const state = {
                ...paramState
            };
            return state;
        },
        dataStateChange(state: any) {
            const gridResultSheet:any = this.$refs.gridResultSheet;
            if(gridResultSheet) gridResultSheet.hideSpinner();
            this.gridLocalize();
            this.loadResultSheet(state);
        },
        loadResultSheet(state:any) {
            if (state.action) {
                delete state.action;
            }

            agent.post<ResultSheetRowResponse>(API_KEY.RESULT_SHEET.GET_RESULT_SHEET,{...state}).then((response) => {
                if (response) {
                    this.resultSheet.data = response.result;
                    this.resultSheet.list = response.result.result;
                }
            }).catch(() => { });
        },
        loadResultSheetDesign(id: number) {
            if (id > 0) {
                agent.get<ResultSheetDesignResponse>(API_KEY.RESULT_SHEET_DESIGN.GET_BY_ID(id)).then((response) => {
                    if (response) {
                        this.resultSheetDesign.data = response.result;
                    }
                }).catch(() => { });
            }
        },
        loadResultSheetDesignHeader(id: number) {
            agent.get<ResultSheetDesignResponse>(API_KEY.RESULT_SHEET_DESIGN.HEADER_BY_ID(id)).then((response) => {
                if (response) {
                    this.resultSheetDesign.dataHeaders = response.result;
                }
            }).catch(() => { });
        },
        changeResultSheet() {
            if (Number(this.resultSheet.selectedId) > 0) {
                const id = Number(this.resultSheet.selectedId);
                this.loadResultSheetDesign(id);
                this.loadResultSheetDesignHeader(id);
            }
        },
        onEditResultSheetDesign(resultSheetDesign: ResultSheetDesign) {
            
            this.resetResultSheetDesignForm();
            this.resultSheetDesign.form = resultSheetDesign;
            const put = {...resultSheetDesign};
            radio.checked("headerSubheader", put.isSubHeader?"subHeader":"header");
            this.resultSheetDesign.isHeader = put.isSubHeader===false;
            this.resultSheetDesign.isUpdate=true;
            this.open(true);
        },
        onDeleteResultSheetDesignConfirm(resultSheetDesign:ResultSheetDesign){
            this.resultSheetDesign.form=resultSheetDesign;
            let title = this.$t("msgSureDeleteSubHeader");
            if(resultSheetDesign.isSubHeader){
                title=this.$t("msgSureDeleteSubHeader");
            }else{
                title= this.$t("msgSureDeleteHeaderSubHeader");
            }
            const app = this;
            Confirmation = DialogUtility.confirm({
                    title: this.$t("deleteConfiramtion"),
                    content: title,
                    okButton: {
                    text: app.$t("ok"),
                    click: async function () {
                        Confirmation.hide();
                        app.onDeleteResultSheetDesign();
                    },
                    },
                    cancelButton: { text: this.$t("cancel") },
                    showCloseIcon: true,
                    closeOnEscape: true,
                    zIndex: 10000,
                    animationSettings: { effect: "Zoom" },
                });
        },
        onDeleteResultSheetDesign() {
            const resultSheetDesign = {...this.resultSheetDesign.form};
            if(resultSheetDesign.id>0){
                agent.delete<ResultSheetDesignResponse>(API_KEY.RESULT_SHEET_DESIGN.DELETE(resultSheetDesign.id)).then((response) => {
                        if (response.result) {
                            toasterService.success(staticMessage.delete);
                            this.resultSheetDesign.form = {} as ResultSheetDesign;
                            this.changeResultSheet();
                        }
                    }).catch(() => { });
            }
        },
        onUpsertResultSheetDesign(event: any) {
            event.preventDefault();

            if(this.resultSheetDesign.isUpdate){
                this.onUpdateResultSheetDesign(this.resultSheetDesign.form);
            }else{
                this.onSaveResultSheetDesign(this.resultSheetDesign.form);
            }
        },
        onSaveResultSheetDesign(resultSheetDesign:ResultSheetDesign){
            const resultSheetId = { resultSheetId: this.resultSheet.selectedId, isSubHeader: this.resultSheetDesign.isHeader == false };
            const post = { ...resultSheetId, ...resultSheetDesign, lastModifiedBy: authService.getUserEmail() };

            if (post.resultSheetId > 0) {
                agent.post<ResultSheetDesignResponse>(API_KEY.RESULT_SHEET_DESIGN.POST, post).then((response) => {
                    if (response.result) {
                        toasterService.success(this.$t("msgSave"));
                        this.resultSheetDesign.form = {} as ResultSheetDesign;
                        this.changeResultSheet();
                        this.close(true);
                    }
                }).catch(() => { });
            }
        },
        onUpdateResultSheetDesign(resultSheetDesign:ResultSheetDesign){

            const resultSheetId = { resultSheetId: this.resultSheet.selectedId, isSubHeader: this.resultSheetDesign.isHeader == false };
            const post = { ...resultSheetId, ...resultSheetDesign, lastModifiedBy: authService.getUserEmail() };

            if (post.id > 0 && post.resultSheetId>0) {
                agent.put<ResultSheetDesignResponse>(API_KEY.RESULT_SHEET_DESIGN.PUT, post).then((response) => {
                    if (response.result) {
                        toasterService.success(this.$t("msgUpdate"));
                        this.resultSheetDesign.form = {} as ResultSheetDesign;
                        this.changeResultSheet();
                        this.close(true);
                    }
                }).catch(() => { });
            }
        },
        onSaveResultSheet() {
            if (this.resultSheet.form.resultSheetName.length > 0) {
                if (this.resultSheet.isUpdate) {
                    let put = { ...this.resultSheet.form,lastModifiedBy: authService.getUserEmail() };
                    agent.put<ResultSheetResponse>(API_KEY.RESULT_SHEET.PUT, put).then((response) => {
                        if (response.result) {
                            this.resetResultSheetForm();
                            this.loadResultSheet(this.initState(this.resultSheet.pageSettings.state));
                            toasterService.success(this.$t("msgUpdate"));
                        }
                    }).catch(() => { });
                } else {
                    let post = { resultSheetName: this.resultSheet.form.resultSheetName,lastModifiedBy:authService.getUserEmail() };
                    agent.post<ResultSheetResponse>(API_KEY.RESULT_SHEET.POST, post).then((response) => {
                        if (response.result) {
                            this.resetResultSheetForm();
                            this.loadResultSheet(this.initState(this.resultSheet.pageSettings.state));
                            toasterService.success(this.$t("msgSave"));
                        }
                    }).catch(() => { });
                }
            }
        },
        onDuplicateResultSheet() {
            if (this.resultSheet.form.id > 0) {
                let upsert = { id: this.resultSheet.form.id, lastModifiedBy: authService.getUserEmail() };
                agent.post<ResultSheetResponse>(API_KEY.RESULT_SHEET.POST_DUPLICATE, upsert).then((response) => {
                    if (response.result) {
                        this.resetResultSheetForm();
                        this.loadResultSheet(this.initState(this.resultSheet.pageSettings.state));
                        toasterService.success(this.$t("msgSave"));
                    }
                }).catch(() => { });
            }
        },
        onDeleteResultSheet() {
            if (this.resultSheet.form.id > 0) {
                agent.delete<ResultSheetResponse>(API_KEY.RESULT_SHEET.DELETE(this.resultSheet.form.id)).then((response) => {
                    if (response.result) {
                        this.resetResultSheetForm();
                        this.loadResultSheet(this.initState(this.resultSheet.pageSettings.state));
                        toasterService.success(staticMessage.delete);
                        this.resultSheetDesign.data = [];
                        this.resultSheet.selectedId = 0;
                    }
                }).catch(() => { });
            }
        },
        onOrdinal(resultSheetDesigns:ResultSheetDesign[]){

            const put = {
                resultSheetDesigns : resultSheetDesigns
            };

            if(put.resultSheetDesigns.length==0) return;

            agent.put<ResultSheetDesignResponse>(API_KEY.RESULT_SHEET_DESIGN.PUT_ORDINAL, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.resultSheetDesign.form = {} as ResultSheetDesign;
                    this.changeResultSheet();
                    this.close(true);
                }
            }).catch(() => { });
        },
        onDragHeader(row: ResultSheetDesign) {
            this.dragItem = row;
            this.resultSheetDesign.oldIndexHeader = this.resultSheetDesign.data.findIndex(x=>x.id==row.id);
        },
        onDropHeader(row: ResultSheetDesign) {
            if (this.dragItem.isSubHeader) {
                this.onDropSubHeader(row);
                return;
            }

            let newIndex = this.resultSheetDesign.data.findIndex(x=>x.id==row.id)
            let oldData = this.resultSheetDesign.data;
            const movedItem = oldData.splice(this.resultSheetDesign.oldIndexHeader, 1)[0];
            this.resultSheetDesign.data.splice(newIndex, 0, movedItem);
            console.log('rs: ', this.resultSheetDesign.data);
            this.onOrdinal(this.resultSheetDesign.data);
        },
        onDragSubHeader(subRow:ResultSheetDesign,row:ResultSheetDesign){
            this.dragItem = subRow;
            this.resultSheetDesign.rowDragId=row.id;
            this.resultSheetDesign.subRowDrag=subRow;
        },
        onDropSubHeader(row:ResultSheetDesign){
            if(row.id===this.resultSheetDesign.rowDragId){
                return;
            }

            let udResultSheetDesign = this.resultSheetDesign.subRowDrag;
            udResultSheetDesign.parentHeaderId = row.id;

            this.onUpdateResultSheetDesign(udResultSheetDesign);
        },
        commandClick: function (args: { commandColumn: any, rowData: ResultSheetRow }) {
            this.resultSheet.isUpdate = false;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.resultSheet.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureDeleteResultSheet")+`:${args.rowData.resultSheetName}?`,
                            okButton: {
                            text: app.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDeleteResultSheet();
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    case 'Edit':
                        this.resultSheet.form = args.rowData;
                        this.resultSheet.isUpdate = true;
                        break;
                    case 'Copy':
                        this.resultSheet.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureDuplicateResultSheet")+`:${args.rowData.resultSheetName}?`,
                            okButton: {
                            text: app.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDuplicateResultSheet();
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    default: break;
                }
            }
        },
        radioOnChange(type: string) {
            if (type.toLowerCase() == 'header') this.resultSheetDesign.isHeader = true;
            if (type.toLowerCase() == 'subheader') this.resultSheetDesign.isHeader = false;
        },
        resetResultSheetDesignForm() {
            this.resultSheetDesign.form = {} as ResultSheetDesign;
            this.resultSheetDesign.isUpdate = false;
            this.resultSheetDesign.isHeader = true;
        },
        resetResultSheetForm() {
            this.resultSheet.form = {} as ResultSheetRow;
            this.resultSheet.isUpdate = false;
            this.resultSheet.form.resultSheetName = '';
        },
        addNewResultSheetDesign() {
            this.resetResultSheetDesignForm();
            radio.checked("headerSubheader", "header");
            this.open(true);
        },
        gridLocalize(){
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }
        },

        open(isDesign: boolean = false) {
            if (isDesign) {
                modal.Open('resultSheetDesignModal');
            } else {
                this.resultSheet.form.resultSheetName = '';
                modal.Open('resultSheetModal');
                this.loadResultSheet(this.initState(this.resultSheet.pageSettings.state));
            }
        },
        close(isDesign: boolean = false) {
            if (isDesign) {
                this.resultSheetDesign.form = {
                    id:0
                } as ResultSheetDesign;
                this.resultSheetDesign.isUpdate = false;
                modal.Close('resultSheetDesignModal');
            } else {
                this.resultSheet.form = {
                    id:0
                } as ResultSheetRow;
                this.resultSheet.isUpdate = false;
                modal.Close('resultSheetModal');
            }
        },
    }
});

