
import { GridComponent as EjsGrid, ColumnsDirective as EColumns, ColumnDirective as EColumn,  Resize, Toolbar, CommandColumn, Page, Sort }
    from '@syncfusion/ej2-vue-grids';
import { defineComponent } from 'vue';
import SourceComponent from '../Source/SourceComponent.vue';
import { BalanceSheetDesign, BalanceSheetDesignResponse, BalanceSheetRow, BalanceSheetRowResponse } from '../BalanceSheetDesign/BalanceSheet.model';
import { BalanceSheetAccountGroupResponse, BalanceSheetMapping, BalanceSheetMappingGetResponse, BalanceSheetMappingResponse } from './BalanceSheetMapping.model';
import agent, { resultSheetServiceBaseURL } from '@/services/agent';
import modal from '@/components/common/modalManage';
import { BalanceSheetAccountGroup } from './BalanceSheetMapping.model';
import staticMessage from '@/components/common/staticMessage';
import toasterService from '@/services/toasterService';
import { Source, SourceGetResponse } from '../Source/Source.model';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { DialogUtility } from '@syncfusion/ej2-vue-popups';
import { ResultSheetAccountGroupGetResponse } from '../AccountMapping/AccountMapping.model';
import authService from '@/services/authService';
import { DropDownListComponent } from '@syncfusion/ej2-vue-dropdowns';

let _data: BalanceSheetMapping[] = [];
let _form: BalanceSheetMapping = {} as BalanceSheetMapping;
let _formBalanceSheetAccountGroup: BalanceSheetAccountGroup = {} as BalanceSheetAccountGroup;
let _balanceSheetData: BalanceSheetRow[] = [];
let _balanceSheetDesignData: BalanceSheetDesign[] = [];
let _accountUnitData: BalanceSheetAccountGroup[] = [];
let _sourceData: Source[] = [];
let _selectedBalanceSheetId: string = "";
let Confirmation:any = null;


export default defineComponent({
    components: {
        SourceComponent,
        "ejs-grid": EjsGrid,
        "e-columns": EColumns,
        "e-column": EColumn,
        "ejs-dropdownlist":DropDownListComponent
    },
    data() {
        return {
            manageSource: false,
            manageMapping: true,
            accountUnit: {
                form: _formBalanceSheetAccountGroup,
                data:_accountUnitData,
                fields:{
                    text: 'name',
                    value: 'id'
                }
            },
            balanceSheetDesign:{
                data: _balanceSheetDesignData,
                fields:{
                    text: 'header',
                    value: 'id'
                }
            },
            source:{
                data: _sourceData,
                fields:{
                    text: 'name',
                    value: 'id'
                }
            },
            mapping: {
                data: _data,
                form: _form,
                selectedId: _selectedBalanceSheetId,
                listBalanceSheet: _balanceSheetData,
                selectedSourceId: 0,
                selectedSubHeaderId: 0,
                selectedBalanceSheetId: 0,
                isUpdate: false,
                fieldsBalanceSheetDesign: { text: 'header', value: 'id' },
                fieldsBalanceSheetAccountGroup: { text: 'name', value: 'id' },
                fieldsSource: { text: 'name', value: 'id' },
                toolbar: ['Search'],
                pageSettings: {
                    pageSizes: [10, 20, 50, 100],
                    pageSize: 50,
                    pageCount: 5
                },
                commands: [
                    { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
                    { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } },
                ],
            },
            locale: ''
        }
    },
    provide: {
        grid: [Toolbar,Resize, CommandColumn, Page,Sort]
    },
    created() {
        agent.setBaseUrl(resultSheetServiceBaseURL);
        this.gridLocalize();
    },
    mounted() {
        this.loadBalanceSheet();
        this.loadBalanceSheetAccountGroup();
        this.loadBalanceSheetDesignPrimaryHeader();
        this.loadSource();
        this.loadMapping();

        //this
    },
    methods: {
        onUpsert(event: any) {
            event.preventDefault();
            if (this.mapping.isUpdate) {
                this.onUpdate();
            } else {
                this.onSave();
            }
        },
        onSave() {
            let post = { ...this.mapping.form,createdBy:authService.getUserEmail() };

            post.balanceSheetId = this.mapping.selectedBalanceSheetId;

            agent.post<BalanceSheetMappingResponse>(`v1/balancesheetmapping`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.close();
                    this.loadMapping();
                }
            }).catch(() => { });
        },
        onUpdate() {
            let put = { ...this.mapping.form,lastModifiedBy:authService.getUserEmail() };

            put.balanceSheetId = this.mapping.selectedBalanceSheetId;

            agent.put<BalanceSheetMappingResponse>(`v1/balancesheetmapping`, put).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgUpdate"));
                    this.reset();
                    this.close();
                    this.loadMapping();
                }
            }).catch(() => { });
        },
        onDelete() {
            let del = { ...this.mapping.form };

            agent.delete<BalanceSheetMappingResponse>(`v1/balancesheetmapping/${del.id}`).then((response) => {
                if (response.result) {
                    toasterService.success(staticMessage.delete);
                    modal.Close(staticMessage.modalConfirm);
                    this.loadMapping();
                }
            }).catch(() => { });
        },
        onSaveBalanceSheetAccountGroup(event: any) {
            event.preventDefault();

            let post = { ...this.accountUnit.form };

            agent.post<BalanceSheetAccountGroupResponse>(`v1/accountgroups`, post).then((response) => {
                if (response.result) {
                    toasterService.success(this.$t("msgSave"));
                    this.loadBalanceSheetAccountGroup();
                    this.close(true);
                }
            }).catch(() => { });
        },
        loadMapping() {
            let query = `v1/balancesheetmapping/getlist`;

            if (this.mapping.selectedBalanceSheetId && this.mapping.selectedBalanceSheetId > 0) {

                query = query + `?activeBalanceSheetId=${this.mapping.selectedBalanceSheetId}`;

                if (this.mapping.selectedSubHeaderId && this.mapping.selectedSubHeaderId > 0) {
                    query = query + `&subHeaderId=${this.mapping.selectedSubHeaderId}`;
                }

                if (this.mapping.selectedSourceId && this.mapping.selectedSourceId > 0) {
                    query = query + `&sourceId=${this.mapping.selectedSourceId}`;
                }
            }

            agent.get<BalanceSheetMappingGetResponse>(query).then((response) => {
                console.log(response);
                if (response) {
                    this.mapping.data = response.result;
                }
            }).catch(() => { });
        },
        loadSource() {
            agent.post<SourceGetResponse>(`v1/source/getsource`,{}).then((response) => {
                if (response) {
                    const data = response.result.result.sort((a, b) => a.name.localeCompare(b.name));
                    const initData = {
                        id: 0,
                        name: this.$t("msgSelectSource")
                    } as Source;
                    this.source.data = [initData,...data];
                }
            }).catch(() => { });
        },
        loadBalanceSheetAccountGroup() {
            agent.post<ResultSheetAccountGroupGetResponse>(`v1/accountgroups/getaccountgroups`,{}).then((response) => {
                if (response) {
                    const data = response.result.result.sort((a, b) => a.name.localeCompare(b.name));
                    const initData = {
                        id: 0,
                        name: this.$t("msgSelectAccountGroup")
                    } as BalanceSheetAccountGroup;
                    this.accountUnit.data = [initData,...data];
                }
            }).catch(() => { });
        },
        loadBalanceSheetDesignPrimaryHeader() {
            agent.get<BalanceSheetDesignResponse>(`v1/balancesheetdesign/getbalancesheetdesignprimaryheader`).then((response) => {
                if (response) {
                    const data = response.result.sort((a, b) => a.header.localeCompare(b.header));
                    const initData = {
                        id: 0,
                        header: this.$t("msgSelectSubHeader")
                    } as BalanceSheetDesign;
                    this.balanceSheetDesign.data = [initData,...data];
                }
            }).catch(() => { });
        },
        loadBalanceSheet() {
            agent.post<BalanceSheetRowResponse>(`v1/balancesheet/getbalancesheet`,{}).then((response) => {
                if (response) {
                    this.mapping.listBalanceSheet = response.result.result;
                }
            }).catch(() => { });
        },
        reset() {
            this.mapping.form = {} as BalanceSheetMapping;
            this.mapping.form.accountGroupId = 0;
            this.mapping.form.subHeaderId = 0;
            this.mapping.form.sourceId = 0;
            this.mapping.isUpdate = false;

            const balanceSheetAccountGroupTree:any = this.$refs.balanceSheetAccountGroupTree;
            if(balanceSheetAccountGroupTree){
                balanceSheetAccountGroupTree.clear();
            }

            const balanceSheetDesignTree:any = this.$refs.balanceSheetDesignTree;   
            if(balanceSheetDesignTree){
                balanceSheetDesignTree.clear();
            }

            const balanceSheetSourceTree:any = this.$refs.balanceSheetSourceTree;
            if(balanceSheetSourceTree){
                balanceSheetSourceTree.clear();
            }
        },
        manage(type: string) {
            switch (type.toLowerCase()) {
                case 'source':
                    this.manageMapping = false;
                    this.manageSource = true;
                    break;
                case 'mapping':
                    this.manageSource = false;
                    this.manageMapping = true;
                    break;
                default: break;
            }
        },
        gridLocalize(){
            this.locale = 'en-grid';
            if (this.$i18n.locale == 'se') {
                setTimeout(() => {
                    import(`@/assets/sv.json`).then(module => {
                        const localText = module.default;
                        this.locale = 'sv';
                        setCulture('sv');
                        L10n.load(localText);
                    });
                });
            }

        },
        commandClick: function (args: { commandColumn: any, rowData: BalanceSheetMapping }) {
            this.mapping.isUpdate = false;
            const app = this;
            if (args) {
                switch (args.commandColumn.type) {
                    case 'Delete':
                        this.mapping.form.id = args.rowData.id;
                        Confirmation = DialogUtility.confirm({
                            title: this.$t("deleteConfiramtion"),
                            content: this.$t("msgSureWantToDelete"),
                            okButton: {
                            text: this.$t("ok"),
                            click: async function () {
                                Confirmation.hide();
                                app.onDelete();
                            },
                            },
                            cancelButton: { text: this.$t("cancel") },
                            showCloseIcon: true,
                            closeOnEscape: true,
                            zIndex: 10000,
                            animationSettings: { effect: "Zoom" },
                        });
                        break;
                    case 'Edit':
                        this.mapping.form = args.rowData;
                        this.mapping.isUpdate = this.mapping.form.id>0;
                        modal.Open('balanceSheetMappingModal');
                        break;
                    default: break;
                }
            }
        },
        open(isBalanceSheetAccountGroup: boolean = false) {
            if (isBalanceSheetAccountGroup) {
                modal.Open('balanceSheetAccGroupModal');
            } else {
                if (!this.mapping.selectedBalanceSheetId || this.mapping.selectedBalanceSheetId == 0) {
                    toasterService.warn("Please select balance sheet!");
                    return;
                }
                modal.Open('balanceSheetMappingModal');
            }
        },
        close(isBalanceSheetAccountGroup: boolean = false) {
            if (isBalanceSheetAccountGroup) {
                modal.Close('balanceSheetAccGroupModal');
            } else {
                this.reset();
                //this.loadMapping();
                modal.Close('balanceSheetMappingModal');
            }
        },
    }

});
